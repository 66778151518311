.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.full-map {
  height: 100vh;
}

.map {
  height: calc(100vh - 180px);
  border-bottom: 3px solid black;
}

.full-height {
  height: 100%;
}

.map-container {
  display: flex;
}

.eighty-width {
  width: 80%;
  border-right: 3px solid black;
}

.input-buffer {
  padding: 2px;
}

.logo-image {
  border-radius: 10px;
}

.logo-image-large {
  height: 180px;
}

.logo-image-small {
  transition: all 3s;
  height: 90px
}

.logo-container {
  position: absolute;
  z-index: 3000;
}

.logo-container-start {
  top: calc(50% - 90px);
  left: calc(50% - 90px);
}

.logo-container-end {
  transition: all 2s;
  top: 0;
  left: calc(50% - 45px);
}

.logo-boxshadow {
  box-shadow: 3px 2px 3px black;
}

.map-opacity-30 {
  opacity: 30%;
}

.map-opacity-full {
  transition: opacity 3s;
  opacity: 1;
}

/* ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
} */

ol {
  cursor: grab;
  margin-right: 24px;
}

form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}